<script>
  import { onMount, onDestroy } from 'svelte';
  import { currentUser } from '../../stores/users';
  import { products } from '../../stores/products';
  import { authorizations, initializeAuthorizationsListener, cleanup } from '../../stores/authorizations';
  import LoadingSpinner from '../../lib/LoadingSpinner.svelte';

  let loading = true;
  let authorizedProductsList = [];

  $: {
    if ($currentUser && $products && $authorizations) {
      authorizedProductsList = $products.filter(product => 
        $authorizations.some(auth => 
          auth.userId === $currentUser.id && 
          auth.productId === product.id
        )
      );
      loading = false;
    }
  }

  onMount(async () => {
    loading = true;
    await initializeAuthorizationsListener();
  });

  onDestroy(() => {
    cleanup();
  });
</script>

<div class="bg-white rounded-lg shadow-md p-6">
  <h2 class="text-lg font-semibold text-gray-900 mb-4">Authorized Products</h2>

  {#if loading}
    <LoadingSpinner />
  {:else if !$currentUser}
    <p class="text-gray-600">Please log in to view authorized products.</p>
  {:else if authorizedProductsList.length === 0}
    <p class="text-gray-600">No authorized products yet. Please contact an administrator to get authorization for products.</p>
  {:else}
    <div class="grid gap-4">
      {#each authorizedProductsList as product (product.id)}
        <div class="border rounded-lg p-4">
          <h3 class="font-medium text-gray-900">{product.name}</h3>
          <p class="text-sm text-gray-600">
            Box Size: {product.boxSize} {product.unit}s/box
          </p>
        </div>
      {/each}
    </div>
  {/if}
</div>