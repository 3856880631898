import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { showSuccess, showError } from '../lib/toast';
import { refreshSession } from '../lib/session';

export async function updateUserProfile(userId, profileData) {
  if (!userId) {
    showError('Invalid user ID');
    return false;
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User profile not found');
    }

    // Merge existing data with updates
    const updatedData = {
      ...userDoc.data(),
      ...profileData,
      updatedAt: new Date().toISOString()
    };

    // Preserve critical fields
    delete updatedData.email;
    delete updatedData.role;
    delete updatedData.createdAt;

    await setDoc(userRef, updatedData, { merge: true });

    // Refresh session to update user data
    await refreshSession();

    showSuccess('Profile updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating profile:', error);
    showError(error.message);
    return false;
  }
}

export async function getUserProfile(userId) {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return {
        id: userDoc.id,
        ...userDoc.data()
      };
    }
    return null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    return null;
  }
}