import { writable } from 'svelte/store';
import { auth, db } from '../lib/firebase';
import { 
  collection,
  onSnapshot,
  query,
  doc,
  getDoc
} from 'firebase/firestore';
import { showError } from '../lib/toast';
import { createStore } from '../lib/store';

// Create stores
export const users = createStore([]);
export const currentUser = createStore(null);
export const loading = writable(false);

// Initialize users collection listener
let unsubscribe;

export function initializeUsersListener() {
  if (unsubscribe) {
    unsubscribe();
  }

  try {
    loading.set(true);
    const usersQuery = query(collection(db, 'users'));
    
    unsubscribe = onSnapshot(usersQuery, 
      async (snapshot) => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        users.set(data);

        // Update current user if authenticated
        if (auth.currentUser) {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            currentUser.set({
              id: userDoc.id,
              ...userDoc.data()
            });
          }
        }
        
        loading.set(false);
      },
      (error) => {
        console.error('Users listener error:', error);
        showError('Failed to load users');
        loading.set(false);
      }
    );

    return unsubscribe;
  } catch (err) {
    console.error('Error setting up users listener:', err);
    showError(err.message);
    loading.set(false);
    return null;
  }
}

// Update current user
export async function updateCurrentUser() {
  if (!auth.currentUser) {
    currentUser.set(null);
    return;
  }

  try {
    const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
    if (userDoc.exists()) {
      currentUser.set({
        id: userDoc.id,
        ...userDoc.data()
      });
    }
  } catch (error) {
    console.error('Error updating current user:', error);
    showError('Failed to update user data');
  }
}

// Cleanup function
export function cleanup() {
  if (unsubscribe) {
    unsubscribe();
    unsubscribe = null;
  }
  currentUser.set(null);
}

// Initialize the store
initializeUsersListener();

// Set up auth state listener
auth.onAuthStateChanged(async (user) => {
  if (user) {
    await updateCurrentUser();
  } else {
    currentUser.set(null);
  }
});