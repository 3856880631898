import { writable, derived } from 'svelte/store';
import { setCookie, getCookie, removeCookie } from '../cookies';

const SESSION_KEY = 'app_session';

function createSessionStore() {
  // Try to load session from cookie first
  let initialState = {
    token: null,
    user: null,
    expiresAt: null,
    lastPath: '/'
  };

  const stored = getCookie(SESSION_KEY);
  if (stored) {
    initialState = stored;
  }

  const { subscribe, set, update } = writable(initialState);

  return {
    subscribe,
    set: (session) => {
      // Always include the current path when updating session
      const updatedSession = {
        ...session,
        lastPath: window.location.pathname
      };
      setCookie(SESSION_KEY, updatedSession);
      set(updatedSession);
    },
    clear: () => {
      // Remove session cookie
      removeCookie(SESSION_KEY);
      
      // Reset to initial state
      set(initialState);
    },
    updatePath: (path) => {
      update(session => {
        const updatedSession = { ...session, lastPath: path };
        if (session.user) { // Only update cookie if user is logged in
          setCookie(SESSION_KEY, updatedSession);
        }
        return updatedSession;
      });
    }
  };
}

export const session = createSessionStore();

export const isAuthenticated = derived(
  session,
  $session => Boolean($session.token && $session.user)
);

export const userRole = derived(
  session,
  $session => $session.user?.role
);