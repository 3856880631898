<script>
  import { onMount } from 'svelte';
  import { session } from '../lib/session';
  import { requireAuth } from '../lib/session/sessionGuard';
  import { showError } from '../lib/toast';
  import { updateUserProfile } from '../services/users';
  import { refreshSession } from '../lib/session/sessionService';
  import AuthorizedProducts from '../components/profile/AuthorizedProducts.svelte';
  import LoadingSpinner from '../lib/LoadingSpinner.svelte';

  let editMode = false;
  let loading = false;
  let formData = {
    companyName: '',
    vatNumber: '',
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      lat: null,
      lng: null
    },
    certifications: {
      globalGap: { number: '', validUntil: '', status: 'valid' },
      grasp: { number: '', validUntil: '', status: 'valid' },
      eco: { number: '', validUntil: '', status: 'valid' }
    }
  };

  onMount(() => {
    if (!requireAuth(['producer'])) return;
    if ($session.user) {
      formData = {
        companyName: $session.user.companyName || '',
        vatNumber: $session.user.vatNumber || '',
        address: {
          street: $session.user.address?.street || '',
          city: $session.user.address?.city || '',
          state: $session.user.address?.state || '',
          country: $session.user.address?.country || '',
          postalCode: $session.user.address?.postalCode || '',
          lat: $session.user.address?.lat || null,
          lng: $session.user.address?.lng || null
        },
        certifications: {
          globalGap: $session.user.certifications?.globalGap || { number: '', validUntil: '', status: 'valid' },
          grasp: $session.user.certifications?.grasp || { number: '', validUntil: '', status: 'valid' },
          eco: $session.user.certifications?.eco || { number: '', validUntil: '', status: 'valid' }
        }
      };
    }
  });

  async function handleSubmit(event) {
    event.preventDefault();
    if (!$session.user?.id || loading) return;

    loading = true;
    try {
      const success = await updateUserProfile($session.user.id, formData);
      if (success) {
        editMode = false;
        await refreshSession();
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      showError(error.message || 'Failed to update profile');
    } finally {
      loading = false;
    }
  }

  function getStatusColor(status) {
    switch (status) {
      case 'valid':
        return 'text-green-600';
      case 'pending':
        return 'text-yellow-600';
      case 'expired':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  }
</script>

<div class="container mx-auto px-4 py-8">
  <div class="bg-white rounded-lg shadow-lg overflow-hidden">
    <!-- Header -->
    <div class="bg-green-600 px-6 py-4">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold text-white">Producer Profile</h1>
        <button
          on:click={() => editMode = !editMode}
          class="px-4 py-2 bg-white text-green-600 rounded-lg hover:bg-green-50 transition-colors"
        >
          {editMode ? 'Cancel Edit' : 'Edit Profile'}
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="p-6">
      {#if loading}
        <div class="flex justify-center py-12">
          <LoadingSpinner />
        </div>
      {:else}
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <!-- Left Column: Company Details & Address -->
          <div class="space-y-6">
            {#if editMode}
              <form on:submit={handleSubmit} class="space-y-6">
                <div class="bg-gray-50 rounded-lg p-6">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">Company Details</h2>
                  <div class="space-y-4">
                    <div>
                      <label for="companyName" class="block text-sm font-medium text-gray-700">Company Name</label>
                      <input
                        id="companyName"
                        type="text"
                        bind:value={formData.companyName}
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                      />
                    </div>
                    <div>
                      <label for="vatNumber" class="block text-sm font-medium text-gray-700">VAT Number</label>
                      <input
                        id="vatNumber"
                        type="text"
                        bind:value={formData.vatNumber}
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="bg-gray-50 rounded-lg p-6">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">Address</h2>
                  <div class="space-y-4">
                    <div>
                      <label for="street" class="block text-sm font-medium text-gray-700">Street Address</label>
                      <input
                        id="street"
                        type="text"
                        bind:value={formData.address.street}
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                      />
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                        <input
                          id="city"
                          type="text"
                          bind:value={formData.address.city}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                      <div>
                        <label for="state" class="block text-sm font-medium text-gray-700">State</label>
                        <input
                          id="state"
                          type="text"
                          bind:value={formData.address.state}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                        <input
                          id="country"
                          type="text"
                          bind:value={formData.address.country}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                      <div>
                        <label for="postalCode" class="block text-sm font-medium text-gray-700">Postal Code</label>
                        <input
                          id="postalCode"
                          type="text"
                          bind:value={formData.address.postalCode}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <label for="lat" class="block text-sm font-medium text-gray-700">Latitude</label>
                        <input
                          id="lat"
                          type="number"
                          step="any"
                          bind:value={formData.address.lat}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                      <div>
                        <label for="lng" class="block text-sm font-medium text-gray-700">Longitude</label>
                        <input
                          id="lng"
                          type="number"
                          step="any"
                          bind:value={formData.address.lng}
                          required
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            {:else}
              <!-- View Mode -->
              <div class="bg-gray-50 rounded-lg p-6">
                <h2 class="text-xl font-semibold text-gray-900 mb-4">Company Details</h2>
                <dl class="grid grid-cols-2 gap-4">
                  <div>
                    <dt class="text-sm font-medium text-gray-500">Company Name</dt>
                    <dd class="mt-1 text-sm text-gray-900">{formData.companyName || 'Not set'}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500">VAT Number</dt>
                    <dd class="mt-1 text-sm text-gray-900">{formData.vatNumber || 'Not set'}</dd>
                  </div>
                </dl>
              </div>

              <div class="bg-gray-50 rounded-lg p-6">
                <h2 class="text-xl font-semibold text-gray-900 mb-4">Address</h2>
                <div class="space-y-2">
                  <p class="text-sm text-gray-900">{formData.address.street || 'No street address'}</p>
                  <p class="text-sm text-gray-900">
                    {[
                      formData.address.city,
                      formData.address.state,
                      formData.address.postalCode
                    ].filter(Boolean).join(', ') || 'No city/state information'}
                  </p>
                  <p class="text-sm text-gray-900">{formData.address.country || 'No country'}</p>
                  {#if formData.address.lat && formData.address.lng}
                    <p class="text-sm text-gray-500 mt-2">
                      Coordinates: {formData.address.lat}, {formData.address.lng}
                    </p>
                  {/if}
                </div>
              </div>
            {/if}
          </div>

          <!-- Right Column: Certifications -->
          <div class="space-y-6">
            {#if editMode}
              <div class="bg-gray-50 rounded-lg p-6">
                <h2 class="text-xl font-semibold text-gray-900 mb-4">Certifications</h2>
                <div class="space-y-6">
                  {#each Object.entries(formData.certifications) as [certType, cert]}
                    <div class="border-t pt-4 first:border-t-0 first:pt-0">
                      <h3 class="text-lg font-medium text-gray-900 mb-3">{certType.toUpperCase()}</h3>
                      <div class="grid grid-cols-2 gap-4">
                        <div>
                          <label class="block text-sm font-medium text-gray-700">Certificate Number</label>
                          <input
                            type="text"
                            bind:value={cert.number}
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                          />
                        </div>
                        <div>
                          <label class="block text-sm font-medium text-gray-700">Valid Until</label>
                          <input
                            type="date"
                            bind:value={cert.validUntil}
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                          />
                        </div>
                        <div class="col-span-2">
                          <label class="block text-sm font-medium text-gray-700">Status</label>
                          <select
                            bind:value={cert.status}
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                          >
                            <option value="valid">Valid</option>
                            <option value="pending">Pending</option>
                            <option value="expired">Expired</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  {/each}
                </div>
              </div>
            {:else}
              <div class="bg-gray-50 rounded-lg p-6">
                <h2 class="text-xl font-semibold text-gray-900 mb-4">Certifications</h2>
                <div class="space-y-6">
                  {#each Object.entries(formData.certifications) as [certType, cert]}
                    <div class="border-t pt-4 first:border-t-0 first:pt-0">
                      <h3 class="text-lg font-medium text-gray-900 mb-2">{certType.toUpperCase()}</h3>
                      <dl class="grid grid-cols-2 gap-4">
                        <div>
                          <dt class="text-sm font-medium text-gray-500">Certificate Number</dt>
                          <dd class="mt-1 text-sm text-gray-900">{cert.number || 'Not set'}</dd>
                        </div>
                        <div>
                          <dt class="text-sm font-medium text-gray-500">Valid Until</dt>
                          <dd class="mt-1 text-sm text-gray-900">
                            {cert.validUntil ? new Date(cert.validUntil).toLocaleDateString() : 'Not set'}
                          </dd>
                        </div>
                        <div class="col-span-2">
                          <dt class="text-sm font-medium text-gray-500">Status</dt>
                          <dd class="mt-1 text-sm font-medium {getStatusColor(cert.status)} capitalize">
                            {cert.status}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  {/each}
                </div>
              </div>
            {/if}
          </div>
        </div>

        {#if editMode}
          <div class="mt-6 flex justify-end">
            <button
              type="submit"
              form="profile-form"
              disabled={loading}
              class="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        {/if}

        <!-- Authorized Products Section -->
        <div class="mt-8">
          <div class="bg-gray-50 rounded-lg p-6">
            <h2 class="text-xl font-semibold text-gray-900 mb-4">Authorized Products</h2>
            <AuthorizedProducts />
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>