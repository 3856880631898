<script>
  import { onMount } from 'svelte';
  import { session } from '../lib/session';
  import { requireAuth } from '../lib/session/sessionGuard';
  import MultiProduceForm from '../components/MultiProduceForm.svelte';
  import NewsFeed from '../components/NewsFeed.svelte';

  onMount(() => {
    // Check if user has access
    if (!requireAuth(['admin', 'producer', 'supermarket'])) {
      return;
    }
  });
</script>

<div class="container mx-auto px-4 py-8">
  {#if $session.user?.role === 'producer'}
    <div class="grid md:grid-cols-2 gap-8">
      <!-- Submit Offer Form -->
      <div>
        <div class="bg-white rounded-lg shadow-lg overflow-hidden">
          <div class="bg-green-600 px-6 py-4">
            <h2 class="text-xl font-semibold text-white">Submit New Offer</h2>
            <p class="mt-1 text-sm text-green-100">Create a new offer for your products</p>
          </div>
          <div class="p-6">
            <MultiProduceForm />
          </div>
        </div>
      </div>

      <!-- News Feed -->
      <div>
        <NewsFeed />
      </div>
    </div>
  {:else}
    <!-- For non-producer users -->
    <div class="max-w-3xl mx-auto">
      <NewsFeed />
    </div>
  {/if}
</div>