import { writable, derived } from 'svelte/store';
import { session } from './session';

export const location = writable(window.location.pathname);
export const isNavigating = writable(false);

// Update location on popstate
window.addEventListener('popstate', () => {
  const path = window.location.pathname;
  location.set(path);
  session.updatePath(path);
});

// Navigation function
export function navigate(to) {
  history.pushState(null, '', to);
  location.set(to);
  session.updatePath(to);
}

// Protected routes configuration
const protectedRoutes = {
  '/': ['admin', 'producer', 'supermarket'],
  '/profile': ['producer'],
  '/offers': ['admin', 'producer', 'supermarket'],
  '/admin': ['admin']
};

// Public routes
const publicRoutes = ['/login'];

// Route store with protection
export const route = derived(
  [location, session],
  ([$location, $session]) => {
    // Check if route is protected
    const requiredRoles = protectedRoutes[$location];
    
    if (requiredRoles) {
      // Route is protected, check if user is authenticated and has required role
      if (!$session.user) {
        navigate('/login');
        return 'login';
      }
      
      if (!requiredRoles.includes($session.user.role)) {
        // User doesn't have required role, redirect to appropriate page
        switch ($session.user.role) {
          case 'admin':
            navigate('/admin');
            return 'admin';
          case 'producer':
          case 'supermarket':
            navigate('/');
            return 'dashboard';
          default:
            navigate('/login');
            return 'login';
        }
      }
    } else if (!publicRoutes.includes($location) && !$session.user) {
      // Non-public route accessed without authentication
      navigate('/login');
      return 'login';
    }

    // Route mapping
    switch ($location) {
      case '/login':
        // Redirect authenticated users from login page
        if ($session.user) {
          const lastPath = $session.lastPath;
          if (lastPath && lastPath !== '/login') {
            navigate(lastPath);
            return getRouteFromPath(lastPath);
          } else if ($session.user.role === 'admin') {
            navigate('/admin');
            return 'admin';
          } else {
            navigate('/');
            return 'dashboard';
          }
        }
        return 'login';
      case '/profile':
        return 'profile';
      case '/offers':
        return 'offers';
      case '/admin':
        return 'admin';
      default:
        return 'dashboard';
    }
  }
);

function getRouteFromPath(path) {
  switch (path) {
    case '/profile':
      return 'profile';
    case '/offers':
      return 'offers';
    case '/admin':
      return 'admin';
    case '/':
      return 'dashboard';
    default:
      return 'dashboard';
  }
}

// Initialize route on page load
if (typeof window !== 'undefined') {
  const path = window.location.pathname;
  location.set(path);
  session.updatePath(path);
}