import { get } from 'svelte/store';
import { session, isAuthenticated } from './sessionStore';
import { navigate } from '../router';
import { showError } from '../toast';

export function requireAuth(allowedRoles = []) {
  const authenticated = get(isAuthenticated);
  const currentUser = get(session).user;

  if (!authenticated) {
    showError('Please log in to access this page');
    navigate('/login');
    return false;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(currentUser?.role)) {
    showError('You do not have permission to access this page');
    navigate('/');
    return false;
  }

  return true;
}

export function redirectIfAuthenticated(path = '/') {
  const authenticated = get(isAuthenticated);
  const currentUser = get(session).user;

  if (authenticated) {
    // Redirect admin users to admin dashboard
    if (currentUser?.role === 'admin') {
      navigate('/admin');
    } else {
      navigate(path);
    }
    return true;
  }
  return false;
}