import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { auth } from './firebase';
import { showSuccess, showError } from './toast';
import { clearSession } from './session';

export async function signIn(email, password) {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    showSuccess('Welcome back!');
    return user;
  } catch (error) {
    console.error('Sign in error:', error);
    showError(error.message);
    throw error;
  }
}

export async function signOut() {
  try {
    // Sign out from Firebase
    await firebaseSignOut(auth);
    
    // Clear session and cookies, redirect to login
    clearSession();
    
    // Show success message
    showSuccess('Logged out successfully');
  } catch (error) {
    console.error('Sign out error:', error);
    showError(error.message);
    throw error;
  }
}